import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class FileService {
  constructor(private http: HttpClient) {}

  downloadPdf(url: string): Subscription {
    return this.http
      .get(url, { responseType: 'blob' })
      .subscribe((response: Blob) => {
        const blob = new Blob([response]);
        const urlArchivo = window.URL.createObjectURL(blob);
        window.open(urlArchivo);
      });
  }
}
